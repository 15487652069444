<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="
        bookmark-wrapper
        align-items-center
        flex-grow-1
        font-weight-bolder
        d-none d-lg-flex
      "
    >
    <b-badge v-if="user.company &&
            user.company.role == 'seller' && level" class="badge badge-secondary mr-1"> Level {{ level }}</b-badge>

      <span v-if="user.company_name"> {{ user.company_name }}</span>
      <!-- <dark-Toggler v-else class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
      >
        <template #button-content>
          <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          />
          <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
        </template>
        <b-dropdown-item
          v-for="localeObj in locales"
          :key="localeObj.locale"
          @click="$i18n.locale = localeObj.locale"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
          <span class="ml-50">{{ $t(localeObj.name) }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <span @click="getTasks" id="show-settings"></span>

      <b-nav-item-dropdown
        class="dropdown-notification"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <feather-icon
            :badge="notifications.length"
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="20"
          />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header" v-if="notifications.length > 0">
          <div class="dropdown-header">
            <b-link
              v-for="(notification, index) in notifications"
              :key="index"
              @click="goToNotification(notification)"
            >
              <b-media>
                <template #aside>
                  <b-avatar
                    size="30"
                    variant="light-danger"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  >
                    <feather-icon icon="InfoIcon" size="15" />
                  </b-avatar>
                </template>
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    {{ $t("task_2_buyer_title") }}
                  </span>
                </p>
                <small class="notification-text">{{
                  notification.description.substring(0, 30)
                }}</small>
                <span
                  class="notification-text"
                  v-if="notification.description.length > 30"
                  >...</span
                >
              </b-media>

              <hr v-if="index != notifications.length - 1" />
            </b-link>
          </div>
        </li>

        <!-- Notifications -->
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div
            class="d-sm-flex d-none user-nav"
            id="header-info"
            @click="changeInfo"
          >
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }} 
            </p>
          </div>

          <span
            class="b-avatar badge-minimal badge-light-primary rounded-circle"
            style="width: 40px; height: 40px"
            ><span class="b-avatar-img">
              <b-avatar variant="primary"></b-avatar></span
            ><span
              class="b-avatar-badge badge-success"
              style="font-size: calc(11.2px); bottom: 0px; right: 0px"
            ></span
          ></span>

          <!-- <b-avatar
            v-if="avatar"
            :variant="avatar == 2 ? 'success' : avatar == 1 ? 'info' : 'dark'"
            size="40"
            badge
            class="badge-minimal"
            badge-variant="success"
          ></b-avatar>
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="avatarUrl"
            class="badge-minimal"
            badge-variant="success"
          /> -->
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToProfile()"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider
          v-if="
            this.$store.state.app.company &&
            this.$store.state.app.company.value &&
            this.$store.state.app.company.value.role == 'seller'
          "
        />
        <b-dropdown-item
          v-if="
            this.$store.state.app.company &&
            this.$store.state.app.company.value &&
            this.$store.state.app.company.value.role == 'seller'
          "
          @click="goToSettings()"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>{{ $t("settings") }} </span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    VuePerfectScrollbar,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      language: "",
      level: null,
      avatarUrl: require("@/assets/images/avatars/13-small.png"),
      avatar: false,
      hideSettings: false,
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: 'english',
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: 'german',
        },
      ],
    };
  },
  watch: {
    '$route' (to, from){
      if(this.$route.path == '/' || this.$route.path == '/invoices' || this.$route.path.includes('/invoice')){
        this.getCompany();
      }
    }
  
  },
  computed: {
    currentLocale() {
      // this.$i18n.locale = this.$store.state.app.company.value.language;
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: 'english',
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name:'german',
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: 'english',
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  
  },
  created() {
    this.getTasks();
    this.getNotificatins();
    this.getCompany();
    this.language = this.$i18n.locale ? this.$i18n.locale : "en";
  },
  methods: {
    goToNotification(not) {
      this.$http.post(`/notification/update/` + not.id).then((res) => {
        if (res) {
          this.getNotificatins();
          this.$router.push("/documents");
        }
      });
    },
    getCompany() {
      this.$http
        .post(`/company/show/${this.$store.state.auth.user.company_id}`)
        .then((res) => {
          if (res.data) {
            this.$store.commit("app/SET_COMPANY_INFO", res.data);
            this.$store.commit("app/COMPANY_INFO", res.data);
            this.$store.state.auth.user.company.level =  res.data.value.level;
            this.level =  res.data.value.level;
            this.$store.state.app.level =  res.data.value.level;
            this.$store.commit("app/UPDATE_LEVEL",  res.data.value.level);

          }
        });
    },

    getNotificatins() {
      this.$http
        .post(`/notification/list/${this.$store.state.auth.user.company_id}`)
        .then((res) => {
          if (res) {
            this.notifications = res.data.value;
          }
        });
    },
    getTasks() {
      this.$http.post("/company/tasks").then((res) => {
        if (
          res.data.value[0] &&
          (res.data.value[0].status == 1 || res.data.value[0].status == 2) &&
          res.data.value[0].task_id == 1
        ) {
          this.hideSettings = true;
        }
      });
    },
    changeInfo() {
      this.$http.post("/profile/show").then((res) => {
        this.user.name =
          res.data.value.first_name + " " + res.data.value.last_name;
      });

      if (this.$store.state.app.profileImg) {
        this.avatarUrl = this.$store.state.app.profileImg;
      }
      if (this.$store.state.app.avatarImg) {
        this.avatar = this.$store.state.app.avatarImg;
      }
    },
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
      document.getElementById("updateSideBar").click();
    },
    goToSettings() {
      if (this.$route.path != "/settings") {
        this.$router.push("/settings");
      }
    },
    goToProfile() {
      if (this.$route.path != "/profile") {
        this.$router.push("/profile");
      }
    },
    logout() {
      // this.$store.commit("auth/SET_USER", []);
      // this.$store.commit("auth/SET_AUTHENTICATED", false);
      // this.$store.commit("auth/UPDATE_TOKEN", null);
      // this.$store.commit("app/SET_COMPANY_INFO", []);
      localStorage.clear();
      window.location.href = "/login";
    },
  },
};
</script>
<style>
.flag-icon {
  width: 30px;
}
</style>
